import React, { useState } from 'react';
import './dictionary.scss';
import {ColorizeCharactersByTone, PinyinConverter } from '../utilities';
import  SearchResult from './definition';
import MinimizeIcon from '@material-ui/icons/Minimize';
import KeyboardUpArrow from '@material-ui/icons/KeyboardArrowUp';
import PlayListAdd from '@material-ui/icons/PlaylistAdd';
import PlayListAddCheck from '@material-ui/icons/PlaylistAddCheck';
import update from 'immutability-helper';

interface SearchResultsProps {
  results: SearchResult[]
  parent: any
  allowAddRemoveMyWords:boolean
}

const SearchResults = (props:SearchResultsProps) => {

    const [expanded, setExpanded] = useState<number[]>([]);

    const onVocabClick = (idVocab: number) => {
      let isExpanded = expanded.includes(idVocab);
      let index = expanded.indexOf(idVocab);

      // Add or remove the definition from the array of expanded definitions.
      let newData:number[] = (!isExpanded)
        ? update(expanded, {$push: [idVocab]})
        : update(expanded, {$splice: [[index, 1]]})

      setExpanded(newData);
    };

    const onCharacterClick = (character: string) => {
      props.parent.handleSearch();
      console.log(character);
    };  

    const onActivateVocab = (idVocab: number) => {
      const newResults = props.results.map(result => {
        if (result.id !== idVocab) 
          return result;
        else {
          let newStateIsActive = !result.isActive;
          result.activate(newStateIsActive);
  
          let newProps = {
            ...result,
            isActive: newStateIsActive
          };
  
          let newResult = new SearchResult(idVocab);
          newResult.isExpanded = true;
          return Object.assign(newResult, newProps);
        }
      });
  
      props.parent.setState({ searchResults: newResults });
    };

    // Since we have multiple search results we need to loop through and process each result.
    let defItem = props.results.filter(x => x !== undefined).map((def, index) => {
      if(!def.pinyin)
      {
        let converter = new PinyinConverter(def.numberedpinyin);
        def.pinyin = converter.convert();
      }

      // Default the display value to the english definition.
      let englishForDisplay:any = def.english; 

      // If the english entry has multple definitions (definitions are separated by /)
      // Then replace the / separators with incrementing bold numbers.
      if(def.english.match(/\//) !== null)
      {
        let definitionCounter = 0;

        englishForDisplay = 
          def.english.split(/\//).map((value, index) => {
            definitionCounter++;
            return <span key={definitionCounter}><b>{definitionCounter}</b>.{value};  </span>
          });
      }
    
      let resultClasses:string[] = ["vocabRow"];
      if(def.isActive)
        resultClasses.push("active");

      if(def.sentences && def.sentences.length > 0)
        resultClasses.push("hasSentences");

      return (
        <div 
          key={def.id}
          className={resultClasses.join(" ")}
          onClick={() => onVocabClick(def.id)}
        >
          <div className="character">
            <ColorizeCharactersByTone characterString={def.character} numberedpinyin={def.numberedpinyin} callback={onCharacterClick}/>
            <span className="pinyin">{def.pinyin}</span>
            {def.hskLevel !== 0 && 
              <span className="hskLevel">Level {def.hskLevel}</span>
            }
          </div>
          <div className={(expanded.includes(def.id)) ? "english" : "english ellipsis"}>{englishForDisplay}</div>
          {!expanded.includes(def.id) &&
            <div className="vocabExpander">
              <MinimizeIcon
                className="expanderIcon"
                fontSize="inherit"
                viewBox="8 18 6 8"/>
            </div>
          }
          {expanded.includes(def.id) && 
            <React.Fragment>
              <div>
                <div style={{ display: "inline-block", width: "93%" }}>
                  {def.lastTestDateUTC &&
                    <div className="additionalInfo">
                      <span>Last Tested on </span>
                      <span>{new Date(def.lastTestDateUTC).toLocaleString('en-US')}</span>
                    </div>}
                  {def.activationDateUTC &&
                    <div className="additionalInfo">
                      <span>Activated on </span>
                      <span>{new Date(def.activationDateUTC).toLocaleString('en-US')}</span>
                    </div>}
                </div>
                <div onClick={() => onActivateVocab(def.id)} className="addRemoveIcon">
                  {(def.isActive) ? <PlayListAddCheck/> : <PlayListAdd/>}
                </div>
                <div className="sentenceCollection">
                  {def.sentences && def.sentences.slice(0,5).map((value:any, index:any) => {
                    return <div key={def.id + '-' + index.valueOf()}>
                      <div className="character">{value.character}</div>
                      <div className="pinyin">{value.pinyin}</div>
                      <div className="english">{value.english}</div>
                      </div>
                  })}
                </div>
              </div>
              <div style={{ textAlign: "center", height: "14px" }}>
                <KeyboardUpArrow
                  fontSize="inherit"
                  viewBox="6 8 12 8"
                  style={{ height: "14px", width:"18px", color: "darkgray" }}/>
              </div>
            </React.Fragment>
          }
          </div>)
      });
    return (
      <div>
        {defItem}
      </div>
    )
};

export default SearchResults;