import * as AppConstants from './constants';
import {Statistic} from './statistics';

export async function openDatabase()
{
    return new Promise((resolve: any, reject: any) => {
        var request = indexedDB.open(AppConstants.APPLICATION, 5);

        request.onerror = (event) => {
            // Handle errors.
            console.log(event);
        };

        request.onupgradeneeded = (event) => {
            let target: any = event.target;
            var db = target.result;

            if(event.oldVersion < 3)
            {
                db.createObjectStore("TestMethods", { keyPath: "id" });
                db.createObjectStore("ActiveVocab", { keyPath: "id" });
            }

            if(event.oldVersion < 4)
                db.createObjectStore("Sentences", {keyPath: "id" });

            if(event.oldVersion < 5)
            {
                db.deleteObjectStore("TestMethods");
                db.createObjectStore("Statistics", { keyPath: "id" });
            }
        };

        request.onsuccess = (event) => {
            let db: IDBDatabase = request.result;
            resolve(db);
        };
    });
}

export function openTrans(
    db: IDBDatabase,
    objectStore: string[],
    mode?: IDBTransactionMode)
{
    var start = new Date().getTime();

    if(!mode)
        mode = "readonly";

    let transaction = db.transaction(objectStore, mode);

    // Do something when all the data is added to the database.
    transaction.oncomplete = (event: any) => {
        var end = new Date().getTime();
        var time = end - start;

        console.log(objectStore + " Transaction Complete in " + time + "ms");
    };

    transaction.onerror = (event: any) => {
        // Don't forget to handle errors!
        console.log("Transaction Error");
    };

    return transaction;
}

export function getObjectStoreTrans(
    db: IDBDatabase,
    objectStore: string,
    mode?: IDBTransactionMode)
{
    let stores:string[] = [];
    stores.push(objectStore);
    return openTrans(db, stores, mode).objectStore(objectStore);
}

export function getDataByKey(store: string, key: any) {
    return new Promise((resolve: any, reject: any) => {
        openDatabase().then((db:any) => {
            let trans = openTrans(db, [store], "readonly");
            let objectStore = trans.objectStore(store);

            objectStore.get(key).onsuccess = (event) => {
                let target: any = event.target;
                let result = target.result;
                resolve(result);
            };
        });
    });
}

export function getNewKey(store: string) {
    console.log("Getting New Key");
    return new Promise<number>((resolve, reject) => {
        openDatabase().then((db:any) => {
            let trans = openTrans(db, [store], "readonly");
            let objectStore = trans.objectStore(store);

            objectStore.getAllKeys().onsuccess = (event) => {
                let target: any = event.target;
                let result: number[] = target.result;

                // Get the lowest index, if below 0, else 0, then subtract 1.
                // This will give us the next negative number for a key value.
                resolve(Math.min(...result, 0) - 1);
            };
        });
    });
}

export function removeKey(store: string, key: any) {
    console.log(`Removing Key: ${key}`);
    return new Promise<void>((resolve, reject) => {
        openDatabase().then((db:any) => {
            let trans = openTrans(db, [store], "readwrite");
            let objectStore = trans.objectStore(store);

            objectStore.delete(key).onsuccess = () => {
                resolve();
            }
        });
    });
}

export async function getActivationData(idVocab: number) {
    return await getDataByKey("ActiveVocab", idVocab);
}

export async function getStatistics(idVocab: number, idTestMethod: number) {
    // Get statstics should return an object with...
    // idVocab, testMethod, lastTestDateUTC, and streak.
    let stat = new Statistic(idVocab, idTestMethod);
    let object: any = await getDataByKey("Statistics", stat.id);

    stat.lastTestDateUTC = object?.lastTestDateUTC;
    stat.streak = object?.streak
    return stat;
}

export function getTestMethodData(idVocab: number) {
    return new Promise((resolve: any, reject: any) => {
        let returnMethods: any = [];

        openDatabase().then((db:any) => {
            let trans = openTrans(db, ["Statistics"], "readonly");
            let statistics = trans.objectStore("Statistics");

            var keyRangeValue = IDBKeyRange.bound(idVocab + "_1", idVocab + "_9");

            statistics.openCursor(keyRangeValue).onsuccess = (event) => {
                let target: any = event.target;
                let cursor = target.result;

                if (cursor) {
                    returnMethods.push(cursor.value);
                    cursor.continue();
                } else {
                    console.log('Entries all displayed.');
                    resolve(returnMethods);
                }
            };
        });
    });
}