import * as LocalStorage from './LocalStorage';
import Vocab from './vocab';
import * as Utils from './utilities';
import * as AppConstants from './constants';

export default class TestCycle {
  public vocabulary: Vocab[];

  constructor() {
    this.vocabulary = [];
  }

  get position() {
    return LocalStorage.GetFromLocalStorage(AppConstants.POSITION_KEY);
  }

  set position(val) {
    localStorage.setItem(AppConstants.POSITION_KEY, JSON.stringify(val));
  }

  get currentRecord() {
    return this.vocabulary[this.position];
  }

  addToTestCycle(data: Vocab, position: number) {
    this.vocabulary[position] = data;			
  }

  loadDataFromStorage(): TestCycle
  {
    let vocabKeys = LocalStorage.GetLocalStorageKeysByPrefix(AppConstants.VOCAB_PREFIX);

    if(vocabKeys.length > 0)
    {
      // Sort the vocab keys so they always appear consistent between program restarts.
      vocabKeys.sort((a, b) =>
      {
        let element1 = Number(a.substr(AppConstants.VOCAB_PREFIX.length));
        let element2 = Number(b.substr(AppConstants.VOCAB_PREFIX.length));
      
        return element1 - element2;
      });

      vocabKeys.forEach((key, index) =>
      {
        let data = LocalStorage.GetFromLocalStorage(key); 
    
        // In standard testing mode we will get the all the test vocab which will allow the user
        // to navigate through the record set.
        if(data)
        {
          let keyIndex = Number(key.substr(AppConstants.VOCAB_PREFIX.length));
          this.addToTestCycle(data, keyIndex);
        }
      });
    
      // When downloading new content the position will be null, otherwise it should have
      // the position where the testing left off, in which case we don't want to zero it out.
      this.position = this.position ?? 0;
    }

    return this;
  }

  getNextReviewItem(): number
  {
    // Re-test a word that the user prreviously answered incorrectly.
    // Store the current record position in localstorage so we can get back
    // to this record after the re-test has completed.
    localStorage.setItem(AppConstants.PREREVIEWPOS, this.position.toString());

    let reviewItems: Array<number> = LocalStorage.GetFromLocalStorage(AppConstants.REVIEWITEMS_KEY);
    let nextPosition: number = -1;

    // So lets find out if there are any review items after our current position.
    for (let index = 0; index < reviewItems.length; index++) {
      let elem = reviewItems[index];
      if (elem > this.position) {
        nextPosition = elem;
        break;
      }

      // If we reach the end of the review items and have not found one to display
      // then we should display the first one.
      if ((index === reviewItems.length - 1) && nextPosition === -1)
        nextPosition = reviewItems[0];
    }

    return nextPosition;
  }

  setPosition(value: number): number
  {
    // This function moves us forward or backward within the vocabulary recordset.
    // Don't let them swipe outside the range of the vocabulary.
    if(this.position === 0 && value === -1)
      return this.position;
      
    let nextPosition: number = -1;

    // Get the list of words that were answered incorrectly.
    let reviewItems: Array<number> = LocalStorage.GetFromLocalStorage(AppConstants.REVIEWITEMS_KEY);

    let prevPos = LocalStorage.GetFromLocalStorage(AppConstants.PREREVIEWPOS);

    // We must decide when firstpass testing is complete. 
    let storedState = Utils.getStoredState();
    let isTestingComplete = storedState.isTestingComplete
      || (this.position === this.vocabulary.length - 1 && value === 1);

    if(isTestingComplete)
    {
      // First store that testing is complete in local storage.
      LocalStorage.Set(AppConstants.STORED_STATE, { isTestingComplete: true });

      // Now that firstpass testing is complete, review any incorrect entries, else we are truly done.
      if(!reviewItems)
      {
        // We are completely done with testing.
        return this.vocabulary.length + 1;
      }
      else
      {
        // Set the position to the next review item.
        nextPosition = this.getNextReviewItem();
      }
    }
    else
    {
      // We are not done with testing.

      // If there is a previous position marked, we should return to it.
      if(prevPos)
      {
        // If we had a previous position set than the last test was a re-test.  We can
        // now jump back to the position saved before the re-test.
        LocalStorage.RemoveFromLocalStorage(AppConstants.PREREVIEWPOS);
        nextPosition = prevPos + 1;
      }
      else if(value === -1)
      {
        nextPosition = this.position - 1;
      }
      else if(reviewItems && (this.position - reviewItems[0] > 5) && (this.position % 11 === 0))
      {
        nextPosition = this.getNextReviewItem();
      }
      else
      {
        nextPosition = this.position + 1;
      }
    }

    this.position = nextPosition;
    return this.position;
  }
};