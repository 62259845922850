export class Statistic {
    id: string;
    idVocab: number;
    testMethod: number;
    lastTestDateUTC?: Date;
    streak?: number

    constructor(
        idVocab: number, 
        testMethod: number,
        lastTestDateUTC?: Date,
        streak?: number) 
    {
        this.id = Statistic.getIDStatistic(idVocab, testMethod);
        this.idVocab = idVocab;
        this.testMethod = testMethod;
        this.lastTestDateUTC = lastTestDateUTC;
        this.streak = streak;
    }

    /** Formats the win/loss streak for display. */
    formatStreak(): string
    {
      let streak = this.streak;
      if(!streak || streak === 0) return "";
    
      return (streak > 0) 
        ? `+ ${streak}`
        : `- ${Math.abs(streak)}`; // We just do this to give the negative a little more space.
    }

    /** Formats supplied paramaters into an id.*/
    static getIDStatistic(idVocab: number, idTestMethod: number) {
        return `${idVocab}_${idTestMethod}`
    }
}