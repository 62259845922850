
export function GetFromLocalStorage(key: string) 
{
    let data = localStorage.getItem(key);
    return (data === null) ? null : JSON.parse(String(data));
}

export function GetLocalStorageKeysByPrefix(keyPrefix: string)
{
    return Object.keys(localStorage).filter((value) => { return value.substr(0, keyPrefix.length) === keyPrefix; });
}

export function Set(key: string, value: any)
{
    localStorage.setItem(key, JSON.stringify(value));
}

export function RemoveFromLocalStorage(keyPrefix: string)
{
    if(localStorage.length === 0) return;

    var keysToRemove = GetLocalStorageKeysByPrefix(keyPrefix);

    keysToRemove.forEach((key) => {
        localStorage.removeItem(key);
    });
}
