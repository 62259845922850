import * as LocalStorage from '../LocalStorage';
import Definition from './definition';
import SearchResult from './definition';

import * as AppConstants from '../constants';
import * as Utils from '../utilities'
import * as indexDBUtils from '../indexdbUtils';
import * as Sentences from '../sentences';
import { Statistic } from '../statistics';

interface DictionaryIndex {
    [key:string]: number[];
}

const englishIndex: DictionaryIndex = require('./Indexes/EnglishDictionaryIndex.json');
const numberedPinyinIndex: DictionaryIndex = require('./Indexes/NumberedPinyinDictionaryIndex.json');
const hskLevelIndex: { [key: string]: string } = require('./Indexes/HSKLevels.json');

let characterIndex: DictionaryIndex;

let preferences = Utils.getPreferences();
if(preferences.characterPreference === "Traditional")
    characterIndex = require('./Indexes/TraditionalDictionaryIndex.json');
else
    characterIndex = require('./Indexes/SimplifiedDictionaryIndex.json');


export class TestMethod {
    constructor(private idVocab: number, private idTestMethod: number) {
        this.idVocab = idVocab;
        this.idTestMethod = idTestMethod;
    }

    getLocalStorageID() {
        return `${AppConstants.TESTMETHOD_PREFIX}${this.idVocab.toString()}_${this.idTestMethod.toString()}`;
    }

    get data() {
        return LocalStorage.GetFromLocalStorage(this.getLocalStorageID());
    }

    set data(value) {
        localStorage.setItem(this.getLocalStorageID(), JSON.stringify(value));
    }
}

export interface activeVocab {
    id: number,
    activationDateUTC: Date
};

class SearchResults {
    results: SearchResult[];

    constructor() {
        this.results = [];
    }

    sort(searchType: string, searchTerm: string) {
        this.results.sort((a: Definition, b: Definition) => {
            switch(searchType)
            {
                case "numberedpinyin":
                    let characterPos = 0;
                    let spaceIndex = 0;
                
                    let aSpaceIndex = a.numberedpinyin.indexOf(" ", spaceIndex);
                    let bSpaceIndex = b.numberedpinyin.indexOf(" ", spaceIndex);
                
                    let aWord = a.numberedpinyin.substr(0, aSpaceIndex);
                    let bWord = b.numberedpinyin.substr(0, bSpaceIndex);
                
                    while((aWord === bWord) && (aSpaceIndex !== -1) && (bSpaceIndex !== -1))
                    {
                        if(a.character.substr(characterPos, characterPos + 1) !== b.character.substr(characterPos, characterPos + 1))
                        {
                            return (a.character < b.character) ? -1 : 1;
                        }
                        else
                        {
                            characterPos++;
                            spaceIndex = aSpaceIndex + 1;
                            aSpaceIndex = a.numberedpinyin.indexOf(" ", spaceIndex);
                            bSpaceIndex = b.numberedpinyin.indexOf(" ", spaceIndex);
                        
                            aWord = (aSpaceIndex !== -1) 
                                ? a.numberedpinyin.substr(spaceIndex, aSpaceIndex)
                                : a.numberedpinyin.substr(spaceIndex);
                    
                            bWord = (bSpaceIndex !== -1) 
                                ? b.numberedpinyin.substr(spaceIndex, bSpaceIndex)
                                : b.numberedpinyin.substr(spaceIndex);
                        }
                    } 
                
                    return (a.numberedpinyin.toLowerCase() < b.numberedpinyin.toLowerCase())
                        ? -1
                        : 1;

                case "english":
                    // Entries with the search term closer to the start of the definition will move up in the sort.
                    let aa = a.english.toLowerCase();
                    let bb = b.english.toLowerCase();

                    if (aa.indexOf(searchTerm) < bb.indexOf(searchTerm))
                        return -1;
                    if (aa.indexOf(searchTerm) === bb.indexOf(searchTerm))
                        return 0;

                    return 1;

                case "character":
                    // An exact match should return first
                    if(a.character === searchTerm)
                        return -1;

                    // Search character at the begining is prefered. 
                    if (a.character.indexOf(searchTerm) < b.character.indexOf(searchTerm))
                        return -1;

                    if (b.character.indexOf(searchTerm) < a.character.indexOf(searchTerm))
                        return 1;                      

                    // shorter length is prefered.
                    if (a.character.length < b.character.length)
                        return -1;

                    if (b.character.length < a.character.length)
                        return 1; 
                        
                    // Alphabetical of pinyin
                    if (a.numberedpinyin < b.numberedpinyin)
                        return -1;

                    if (b.numberedpinyin < a.numberedpinyin)
                        return 1;

                    return 0;

                default:
                    return 0;
            }
        });
    }
};

export default class Dictionary {
    definitions: Definition[];

    constructor() {
        this.definitions = [];
    }

    async populate() {
        // Use the seperate indexes to build up the overall dictionary of words.

        // For each defintion in the index add the entry to the dictionary.
        // Each defnition maps to an array of idVocabs, so we need to .forEach over all of them.

        let createDefinition = ((idVocab: number) => {
            let dict = this.definitions;

            if(dict[idVocab] === undefined)
                dict[idVocab] = new Definition(idVocab);

            dict[idVocab][propertyName] = definition;

            // If we are populating the character property we have an oppertunity
            // to find the hskLevel, if it exists in the hsk index.
            if(propertyName === "character") {
                let level = hskLevelIndex[definition];

                if(level)
                    dict[idVocab]["hskLevel"] = Number(level);
            }
        });

        let start = new Date().getTime();
        let definition: string;
        let propertyName: string;

        for (definition in englishIndex) {
            if (englishIndex.hasOwnProperty(definition)) {
                propertyName = "english"; // This is used by the callback
                englishIndex[definition].forEach( createDefinition )
            }
        }

        for (definition in characterIndex) {
            if (characterIndex.hasOwnProperty(definition)) {
                propertyName = "character"; // This is used by the callback
                characterIndex[definition].forEach( createDefinition )
            }
        };

        for (definition in numberedPinyinIndex) {
            if (numberedPinyinIndex.hasOwnProperty(definition)) {
                propertyName = "numberedpinyin"; // This is used by the callback
                numberedPinyinIndex[definition].forEach( createDefinition )
            }
        }

        this.getActiveObjects(this.definitions);
        this.getSentences(this.definitions);

        // Finally apply the user made changes to the definitions since the last sycronization.
        var activeVocabKeys = LocalStorage.GetLocalStorageKeysByPrefix(AppConstants.ACTIVEUPDATE_PREFIX);

        activeVocabKeys.forEach((key) =>
        {
            var id = parseInt(key.substring(AppConstants.ACTIVEUPDATE_PREFIX.length));
            var isActive = LocalStorage.GetFromLocalStorage(key);

            this.definitions[id].isActive = isActive;

            var isFlagged:boolean = LocalStorage.GetFromLocalStorage(AppConstants.FLAGGEDVOCAB_PREFIX + id);

            if(isFlagged !== null)
                this.definitions[id].isFlagged = isFlagged;

        }, this);

        var end = new Date().getTime();
        var time = end - start;

        console.log("populate: " + time + "ms");
    }

    getSentences(myDefinitions:Definition[]):Promise<Definition[]> {
        console.log("Loading Sentences for Dictionary");

        return new Promise((resolve: any, reject: any) => {
          let sentences:Sentences.Sentence[] = [];
    
          indexDBUtils.openDatabase().then((db:any) => {
            let sentenceStore = indexDBUtils.getObjectStoreTrans(db, "Sentences", "readonly");
            sentenceStore.getAll().onsuccess = (event) => {
                let target: any = event.target;
                sentences = target.result;
                
                if(sentences)
                {
                    // For each definition look at all the sentences to see if our definition is used within.
                    myDefinitions.forEach(def => {
                        sentences.filter(s => s.character.indexOf(def.character) !== -1)
                            .forEach(x => {
                                def.sentences = def.sentences ?? [];

                                if(!def.sentences.includes(x))
                                    def.sentences.push(x)
                        })
                    });
                }

                resolve();

                console.log("Loading Sentences for Dictionary: Complete");
            }
          });
        });
    };

    getActiveObjects(myDefinitions:Definition[]):Promise<Definition[]> {
        return new Promise((resolve: any, reject: any) => {
          let activeObjects:[] = [];
          let statisticsObjects:Statistic[] = [];
    
          indexDBUtils.openDatabase().then((db:any) => {
            let activeObjectStore = indexDBUtils.getObjectStoreTrans(db, "ActiveVocab", "readonly");
            activeObjectStore.getAll().onsuccess = (event) => {
              let target: any = event.target;
              activeObjects = target.result;
    
              if (activeObjects) {
                // We will use a regular old for loop for performance optimization.
                let i: number = 0;
                let len = activeObjects.length;
    
                for (i; i < len; i++) {
                    let ao:any = activeObjects[i];
                    let itemToAdd = myDefinitions[ao.id];
                    if (itemToAdd) {
                        itemToAdd.isActive = true;
                        itemToAdd.activationDateUTC = ao.activationDateUTC;
                    }
                }
    
                resolve();
              }
            };

            let statistics = indexDBUtils.getObjectStoreTrans(db, "Statistics", "readonly");
            statistics.getAll().onsuccess = (event) => {
                const { target }:any = event;
                statisticsObjects = target.result;
      
                if(statisticsObjects) {
                    statisticsObjects.forEach((stat:Statistic) => {
                        let itemToAdd = myDefinitions[stat.idVocab];
                        if (itemToAdd) {
                            itemToAdd.lastTestDateUTC = stat.lastTestDateUTC;
                            itemToAdd.streak = stat.streak;
                        }
                    });
      
                  resolve();
                }
            };
          });
        })
    };

    search(searchType: string, searchTerm: string, filterOn?: string): SearchResults {

        // Search the in memory index for the search term. The logic within this function determines what constitutes a match.
        var searchArray = getSearchArray(searchType);

        // If the filterOn value is not set, we can infer how we should search based on the search type. 
        if(filterOn === undefined) {
            switch(searchType) {
                case "english":
                    // Match searchTerm anywhere in the index.
                    filterOn = "contains";
                    break;

                case "numberedpinyin":
                    if(searchTerm.search(/[1-5]$/) !== -1) 
                        // If the search ends with a valid tone number, then exactly match.
                        filterOn = "exactMatch";
                    else if(searchTerm.match(/\d+/g) != null) 
                        // If the search contains a valid tone number, then the index should begin with the search term.
                        filterOn = "startsWith";
                    else
                        // If the pinyin search contains a space and no numbers, ignore the numbers so we get any combination of tones. (qing1 fu, qing2 fu, etc.)
                        filterOn = (searchTerm.indexOf(' ') >= 0) ? "startsWithAndIgnoreNumbers" : "exactMatchIgnoreNumbers";

                    break;

                case "character":
                    // Normally we search Chinese by finding matches that starts with the character entered.
                    // If the first character is a question mark try using a contains search.
                    if (searchTerm.indexOf("?", 0) !== -1 || searchTerm.indexOf("？", 0) !== -1)
                    {
                        filterOn = "contains";
                        searchTerm = searchTerm.slice(1);
                    }
                    else
                    {
                        filterOn = "startsWith";
                    }
                    
                    break;

                default:
                    // Index must begin with searchTerm.
                    filterOn = "startsWith";
                    break;
            }
        }

        var lowerCaseTerm = searchTerm.toLowerCase();
        var searchTermLength = lowerCaseTerm.length;

        var searchArrayResults = Object.keys(searchArray).filter(function(value) {
            switch(filterOn) {
                case "exactMatch":
                    return value.toLowerCase() === lowerCaseTerm;

                case "exactMatchIgnoreNumbers":
                    return value.toLowerCase().replace(/(\s+|\d+)/g, '').indexOf(lowerCaseTerm, 0) === 0;

                case "contains":
                    return value.toLowerCase().indexOf(lowerCaseTerm, 0) > -1;

                case "containsAfterInitial":
                    return value.toLowerCase().indexOf(lowerCaseTerm, 1) > -1;

                case "startsWith":
                    return value.toLowerCase().substring(0, searchTermLength) === lowerCaseTerm;

                case "startsWithAndIgnoreNumbers":
                    return value.toLowerCase().replace(/\d+/g, '').indexOf(lowerCaseTerm, 0) === 0;

                default:
                    return value.toLowerCase().substring(0, searchTermLength) === lowerCaseTerm; //startsWith
            }
        });

        // To improve performance we could limit the search results.
        var maxSearchResults = 1000;
        var searchResultsCounter = 0;

        var searchResults = new SearchResults();
        var maxIterations = Math.min(searchArrayResults.length, maxSearchResults);

        if(searchArrayResults.length > 0)
        {
            // If we have search results, get the words that have been looked up (history).
            var lookupHistory: string[] = LocalStorage.GetFromLocalStorage(AppConstants.LOOKUPHISTORY_KEY);
            if(lookupHistory)
            {
                // If the previous history entry is contained in the new search term, then discard it.
                if(searchTerm.indexOf(lookupHistory[0]) !== -1)
                    lookupHistory.shift();

                // Remove the search term if it already existed in the list and then add it to the beginning.
                lookupHistory = lookupHistory.filter((value) => {return value !== searchTerm});
                
                if(lookupHistory.length === 0 || lookupHistory[0].indexOf(searchTerm) === -1)
                    lookupHistory.unshift(searchTerm);

                // Limit history to last 25 lookups.
                if(lookupHistory.length > 25)
                    lookupHistory.pop();
            }
            else
            {
                // There is no history, so initialize it.
                lookupHistory = [];
                lookupHistory.push(searchTerm);
            }

            localStorage.setItem(AppConstants.LOOKUPHISTORY_KEY, JSON.stringify(lookupHistory));
        }

        // Loop over the matched search terms.
        for(;searchResultsCounter < maxIterations; searchResultsCounter++) {

            var result = searchArrayResults[searchResultsCounter];
            
            //var searchPosition = sortResult(searchTerm, result);

            // This value will be an array of idVocab's.
            var ids: number[] = searchArray[result];

            for(var idCount = ids.length; idCount--;)
            {
                var entry = this.definitions[ids[idCount]];

                //entry.searchPosition = searchPosition;

                // It is possible that the indexes do not contain the exact same entries as the definitions. 
                // It is also possible that the dictionary entry may be incomplete. In these rare cases just keep moving on.
                if(entry === undefined || entry.character === undefined)
                    continue;

                searchResults.results.push(entry);	
            }
        }

        searchTerm = searchTerm.toLowerCase();
        searchResults.sort(searchType, searchTerm);        
        return searchResults;
    }
};

function getSearchArray(searchType: string): DictionaryIndex
{
    // return the specific index based on the data being entered (simplified, english, pinyin).
    switch(searchType)
    {
        case "numberedpinyin":
            return numberedPinyinIndex;

        case "english":
            return englishIndex;

        case "character":
            return characterIndex;
    }

    throw new Error("Search Type was not valid");
}