import React from 'react';
import * as AppConstants from './constants';
import * as LocalStorage from './LocalStorage';
//import { useState, useEffect } from 'react';

interface DictionaryIndex {
    [key:string]: number[];
}

const simplifiedIndex: DictionaryIndex = require('./Dictionary/Indexes/SimplifiedDictionaryIndex.json');
const numberedPinyinIndex: DictionaryIndex = require('./Dictionary/Indexes/NumberedPinyinDictionaryIndex.json');

export interface StoredState {
	isTestingComplete: boolean
}

export function getStoredState(): StoredState {
	return LocalStorage.GetFromLocalStorage(AppConstants.STORED_STATE) ||
		{
			isTestingComplete: false
		}
}

export interface MyPreferences {
	characterPreference: string,
	maximumCharactersPerTestSession: number
}

export function getPreferences(): MyPreferences {
	return LocalStorage.GetFromLocalStorage(AppConstants.SETTINGS_KEY) ||
		{
			characterPreference: "Simplified",
			maximumCharactersPerTestSession: 50
		};
};



interface CharacterProps {
    characterString: string, 
    numberedpinyin: string,
	callback: Function
}

export function ColorizeCharactersByTone(props: CharacterProps): JSX.Element {
	let displayOutput;

	// Given a string of Chinese characters (一二三), colorize the characters
	// based on the tone markings in the numbered pinyin（yi1 er4 san1).
	let numberPattern = /\d+/g;
	let tones = props.numberedpinyin.match(numberPattern);

	if(tones) {
		// For each character in the string output the character with the appropriate className.
		displayOutput = Array.from(props.characterString).map((character, index) => 
		{
			let className = "";

			switch(tones![index])
			{
				case "1":
					className = "red";
					break;
				case "2":
					className = "green";
					break;
				case "3":
					className = "blue";
					break;
				case "4":
					className = "purple";
					break;
				default:  // tone 5
					className = "ashgray";
					break;
			}
			
			return <span key={index} className={className} onClick={() => props.callback(character)}>{character}</span>;
		});

		return <span>{displayOutput}</span>;
	}
	else
	{
		return <span key={props.characterString}>{props.characterString}</span>;
	}
}

export function showHideSpinner({show = true})
{
  let spinner = document.getElementById("spinBlocker");
  if(!spinner) return;

  spinner.style.display = show ? "block" : "none";
}


export function generateGUID() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
		return v.toString(16);
	});
}

export function isNullOrEmpty(str: string) {
	return str === null || str.trim() === ""
}

export function right(str: string, length: number) {
	return str.slice(str.length - length, str.length);
}

export function left(str: string, length: number) {
	return str.slice(0, length - str.length);
}

export function updatePunctuation(text: string, endWithPeriod: boolean = false): string {
	const chineseCharacters = /[\u4e00-\u9fa5]/g;
	if(chineseCharacters.test(text)) // by true, It means there are Chinese characters
	{
		text = text.replace(".", "。");
		text = text.replace(",", "，");
		text = text.replace("!", "！");
		text = text.replace("?", "？");

		let chineseFinalPunctuation = ["。", "，", "？", "！", "；", "。。。"];
		if(endWithPeriod && !chineseFinalPunctuation.includes(right(text, 1)))
			text = `${text}。`;
	}
	else
	{
		text = text.replace("。", ".");
		text = text.replace("，", ",");
		text = text.replace("！", "!");
		text = text.replace("？", "?");

		let englishFinalPunctuation = [".", "?", "!", ";", "..."];
		if(endWithPeriod && !englishFinalPunctuation.includes(right(text, 1)))
			text = `${text}.`;
	}

	return text;
}

/*
export function useLongPress(callback = () => { }, ms = 300) {
	const [startLogPress, setStartLongPress] = useState(false);

	useEffect(() => {
		let timerId:any;
		if (startLogPress) {
			timerId = setTimeout(callback, ms);
		} else {
			clearTimeout(timerId);
		}

		return () => {
			clearTimeout(timerId);
		};
	}, [startLogPress]);

	return {
		onMouseDown: () => setStartLongPress(true),
		onMouseUp: () => setStartLongPress(false),
		onMouseLeave: () => setStartLongPress(false),
		onTouchStart: () => setStartLongPress(true),
		onTouchEnd: () => setStartLongPress(false),
	};
}
*/

export function pinyinMatchesSearchTerm(
	pinyin: string, searchTerm: string) {

	if(isNullOrEmpty(pinyin) || isNullOrEmpty(searchTerm))
		return false;

	// Strip out spaces for comparison.
	pinyin = pinyin.toLowerCase().replace(/\s+/g, '');
	searchTerm = searchTerm.toLowerCase().replace(/\s+/g, '');

	if(pinyin.includes(searchTerm))
		return true;

	// If the search term does not include a number then remove the numbers and see if there is a match.
	if(!(/\d/.test(searchTerm)) && pinyin.replace(/[0-9]/g, '').includes(searchTerm.replace(/[0-9]/g, '')))
		return true;

	return false;
}

export function getPinyinFromCharacterString(characters: string): string {
	let pinyinArray: string[] = [];
	let matches = [];
	let characterIndex = 0;
	let characterCount = 1;

	var letters = /[a-zA-Z]+$/g;
	if(characters.trim().match(letters))
		return "";

	// The pinyin will use english style punctuation, so substitute those in.
	characters = characters.replace("。", ".");
	characters = characters.replace("，", ",");
	characters = characters.replace("！", "!");
	characters = characters.replace("？", "?");

	// Loop through all the characters in a string, so we can get their idVocab.
	while(characterIndex < characters.length)
	{
	  matches = [];

	  let searchString = characters.substring(characterIndex, characterIndex + characterCount);

	  let skipCharacters = ['.', ',', '!', '?']

	  // If the character is a punctuation mark, add it to our array and move onto the next character.
	  if(skipCharacters.some(x => x === searchString))
	  {
		pinyinArray.push(searchString.trim());
		characterIndex++;
		continue;
	  }

	  // Find all the words that start with the searchString.
	  for(let entry in simplifiedIndex)
	  {
		if(entry.startsWith(searchString))
		  matches.push(entry);
	  }
	  
	  // We may come across things we can't match on, like punctuation marks or numbers. 
	  // Just move along.
	  if(matches.length === 0)
	  {
		pinyinArray.push(searchString);
		characterIndex++;
		continue;
	  }

	  // Remove words from the list that don't match the next n characters in our string.
	  // We should always end up with one match.
	  let results = reduceMatches(matches, characters, characterIndex, characterCount);
	  pinyinArray.push(getKeyByValue(numberedPinyinIndex, simplifiedIndex[results[0]]) || "");
	  characterIndex += results[0].length;
	}

	// Build the final pinyin sentence from the word array.
	let returnSentence = "";
	for(let i = 0; i <= pinyinArray.length - 1; i++)
	{
	  switch(pinyinArray[i])
	  {
		case ".":
		case "!":
		case "?":
		  returnSentence = returnSentence.trim().concat(pinyinArray[i]);
		  break;

		case ",":
		  returnSentence = returnSentence.trim().concat(pinyinArray[i], " ");
		  break;

		default:
		  returnSentence += pinyinArray[i].replace(/\s+/g, '').concat(" ");
		  break;
	  }
	}

	return returnSentence;
  }

  function reduceMatches(
	matches: string[], 
	characters: string, 
	characterIndex: number, 
	characterCount: number): string[] 
  {
	// This function takes an array and removes items that don't match the supplied string.
	// For example, given the string "一二三美元", and the array...

	// [一]
	// [一二三十]
	// [一九九九]
	// [一二三]

	// We would end up with [一二三]
	
	if(matches.length <= 1) return matches;

	// The first character should always match, so we will remeber that,
	// but we want to look for longer matches.
	let firstCharacter = characters.substring(characterIndex, characterIndex + 1);

	characterCount++;
	let searchString = characters.substring(characterIndex, characterIndex + characterCount);

	// Because we are removing items from the array, we need to loop through 
	// the matches in reverse order.
	for(let i = matches.length - 1; i >= 0; i--)
	{
		// remove items that don't match
		if(matches[i] !== searchString)
			matches.splice(i, 1);
	}

	// If we ended up with no matches, return the first character, which should always match.
	if(matches.length === 0) return [firstCharacter];

	return matches;
  };

  function getKeyByValue(object: DictionaryIndex, value: number[]) {
	// In this case our value is an array, so we need to loop through trying to match our value.
	// "ta1":[86963,86960,86962,234932,221538]
	return Object.keys(object).find(key => object[key].find((item: number) => item === value[0]));
  }

export class PinyinConverter {
	entry: string;
	pinyinRegex: any;
	vowels: any;
	pinyin: any;
	accentMap: any;

	constructor (entry: string) {
		this.entry = entry;
		this.pinyinRegex = /(shuang|chuang|zhuang|xiang|qiong|shuai|niang|guang|sheng|kuang|shang|jiong|huang|jiang|shuan|xiong|zhang|zheng|zhong|zhuai|zhuan|qiang|chang|liang|chuan|cheng|chong|chuai|hang|peng|chuo|piao|pian|chua|ping|yang|pang|chui|chun|chen|chan|chou|chao|chai|zhun|mang|meng|weng|shai|shei|miao|zhui|mian|yong|ming|wang|zhuo|zhua|shao|yuan|bing|zhen|fang|feng|zhan|zhou|zhao|zhei|zhai|rang|suan|reng|song|seng|dang|deng|dong|xuan|sang|rong|duan|cuan|cong|ceng|cang|diao|ruan|dian|ding|shou|xing|zuan|jiao|zong|zeng|zang|jian|tang|teng|tong|bian|biao|shan|tuan|huan|xian|huai|tiao|tian|hong|xiao|heng|ying|jing|shen|beng|kuan|kuai|nang|neng|nong|juan|kong|nuan|keng|kang|shua|niao|guan|nian|ting|shuo|guai|ning|quan|qiao|shui|gong|geng|gang|qian|bang|lang|leng|long|qing|ling|luan|shun|lian|liao|zhi|lia|liu|qin|lun|lin|luo|lan|lou|qiu|gai|gei|gao|gou|gan|gen|lao|lei|lai|que|gua|guo|nin|gui|niu|nie|gun|qie|qia|jun|kai|kei|kao|kou|kan|ken|qun|nun|nuo|xia|kua|kuo|nen|kui|nan|nou|kun|jue|nao|nei|hai|hei|hao|hou|han|hen|nai|rou|xiu|jin|hua|huo|tie|hui|tun|tui|hun|tuo|tan|jiu|zai|zei|zao|zou|zan|zen|eng|tou|tao|tei|tai|zuo|zui|xin|zun|jie|jia|run|diu|cai|cao|cou|can|cen|die|dia|xue|rui|cuo|cui|dun|cun|cin|ruo|rua|dui|sai|sao|sou|san|sen|duo|den|dan|dou|suo|sui|dao|sun|dei|zha|zhe|dai|xun|ang|ong|wai|fen|fan|fou|fei|zhu|wei|wan|min|miu|mie|wen|men|lie|chi|cha|che|man|mou|mao|mei|mai|yao|you|yan|chu|pin|pie|yin|pen|pan|pou|pao|shi|sha|she|pei|pai|yue|bin|bie|yun|nüe|lve|shu|ben|ban|bao|bei|bai|lüe|nve|ren|ran|rao|xie|re|ri|si|su|se|ru|sa|cu|ce|ca|ji|ci|zi|zu|ze|za|hu|he|ha|ju|ku|ke|qi|ka|gu|ge|ga|li|lu|le|qu|la|ni|xi|nu|ne|na|ti|tu|te|ta|xu|di|du|de|bo|lv|ba|ai|ei|ao|ou|an|en|er|da|wu|wa|wo|fu|fo|fa|nv|mi|mu|yi|ya|ye|me|mo|ma|pi|pu|po|yu|pa|bi|nü|bu|lü|lu:|e|o|a)r?[1-5]/gi;
		this.vowels = {
			'a*': '0',
			'e*': '1',
			'i*': '2',
			'o*': '3',
			'u*': '4',
			'ü*': '5',
			'A*': '6',
			'E*': '7',
			'I*': '8',
			'O*': '9',
			'U*': '10',
			'Ü*': '11'
		};
		this.pinyin = {
			1: ['ā', 'ē', 'ī', 'ō', 'ū', 'ǖ', 'Ā', 'Ē', 'Ī', 'Ō', 'Ū', 'Ǖ'],
			2: ['á', 'é', 'í', 'ó', 'ú', 'ǘ', 'Á', 'É', 'Í', 'Ó', 'Ú', 'Ǘ'],
			3: ['ǎ', 'ě', 'ǐ', 'ǒ', 'ǔ', 'ǚ', 'Ǎ', 'Ě', 'Ǐ', 'Ǒ', 'Ǔ', 'Ǚ'],
			4: ['à', 'è', 'ì', 'ò', 'ù', 'ǜ', 'À', 'È', 'Ì', 'Ò', 'Ù', 'Ǜ'],
			5: ['a', 'e', 'i', 'o', 'u', 'ü', 'A', 'E', 'I', 'O', 'U', 'Ü']
		  };
		this.accentMap = this.getAccentMap();
	}

    convert(): string {
		let matches = this.entry.match(this.pinyinRegex);
		if (!matches)
		  return this.entry;
  
		for (let j = 0; j < matches.length; j++) {
		  let match = matches[j];
		  let replacement = this.getReplacement(match);
		  this.entry = this.entry.replace(match, replacement);
		}

		return this.entry;
	  }

	private getReplacement(match: string) {
		let accentMap = this.getAccentMap();
		let tone = match.slice(-1);
		let word = match.slice(0, -1).replace('u:','ü').replace('v', 'ü').replace('V', 'Ü');
		for (let base in accentMap) {
		  let vowel = accentMap[base];

		  if (word.indexOf(base) >= 0) {
			let vowelChar = vowel.match(/.\*/)[0];
			let vowelNum = this.vowels[vowelChar];
			let accentedVowelChar = this.pinyin[tone.toString()][vowelNum];
			return word.replace(base, vowel).replace(vowelChar, accentedVowelChar);
		  }
		}
		
		return match;
	  }

    private getAccentMap() {
		if (!this.accentMap) {
			let stars = "a*i a*o e*i ia* ia*o ie* io* iu* A*I A*O E*I IA* IA*O IE* IO* IU* o*u ua* ua*i ue* ui* uo* üe* O*U UA* UA*I UE* UI* UO* ÜE* A* E* I* O* U* Ü* a* e* i* o* u* ü*";
			let nostars = stars.replace(/\*/g, '');
			let starsArray = stars.split(' ');
			this.accentMap = {};
			let ref = nostars.split(' ');

			for (let i = 0; i < ref.length; ++i)
				this.accentMap[ref[i]] = starsArray[i];
		}

		return this.accentMap;
	}
}