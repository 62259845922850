import React, { useState } from 'react';
import * as AppConstants from './constants';
import * as LocalStorage from './LocalStorage';
import Page from './index';
import Drawer from '@material-ui/core/Drawer';
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import { Settings, History, School, Info, Search, CloudUpload, CloudDownload, Cached, Notes } from '@material-ui/icons';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import * as Utils from './utilities'

interface MyProps {
    classes: any,
    parent: Page,
    isDrawerOpen: boolean
}

const styles = (theme:any) => ({
    list: {
        width: 'auto',
    },
    fullList: {
        width: 'auto',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginRight: theme.spacing(1),
    }
  });


    export enum pageIndex {
        TEST = 0,
        DICTIONARY = 1,
        HISTORY = 2,
        MYWORDS = 3,
        HSKLEVELS = 4,
        SENTENCES = 5,
        PREFERENCES = 6
    }

    export enum menuNodes {
        TESTING = "TESTING",
        DICTIONARY_MENU = "DICTIONARY_MENU",
        LOOKUP = "LOOKUP",
        MYWORDS = "MYWORDS",
        HSKLEVELS = "HSKLEVELS",
        SENTENCES = "SENTENCES",
        HISTORY_MENU = "HISTORY_MENU",
        TESTHISTORY = "TESTHISTORY",
        DATASERVICES_MENU = "DATASERVICES_MENU",
        PREFERENCES = "PREFERENCES"
    }

export function DrawerList(props: MyProps) {

    const getPageIndex = (page: string):number => {
        switch (page) {
            case "TEST":
                return pageIndex.TEST
            case "DICTIONARY":
                return pageIndex.DICTIONARY
            case "HISTORY":
                return pageIndex.HISTORY;
            case "MYWORDS":
                return pageIndex.MYWORDS;
            case "HSKLEVELS":
                return pageIndex.HSKLEVELS;
            case "SENTENCES":
                return pageIndex.SENTENCES;
            case "PREFERENCES":
                return pageIndex.PREFERENCES;

            default:
                return pageIndex.TEST;
        }
    }

    let index: number = getPageIndex(props.parent.state.lastPageVisited);

    const [selectedIndex, setSelectedIndex] = useState(index);
    const [historyMenuExpanded, setHistoryMenuExpanded] = useState((index ===2) ? true : false);
    const [dataServicesMenuExpanded, setDataServicesMenuExpanded] = useState(false);
    const [dictionaryMenuExpanded, setDictionaryMenuExpanded] = useState(false);
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
    const [preferences, setPreferences] = useState(() => {
        let myPreferences = Utils.getPreferences();

        return {
            characterPreference: myPreferences.characterPreference,
            maximumCharactersPerTestSession: myPreferences.maximumCharactersPerTestSession
        }
    });

    const toggleDrawer = (open: boolean) => () => {
        props.parent.setState({isDrawerOpen: open});
    };

    const handleSaveSettings = () => {
        LocalStorage.Set(AppConstants.SETTINGS_KEY, preferences);
        setSettingsDialogOpen(false);
    };

    const handleSettingsDialogToggle = (isOpen:boolean) => {
        LocalStorage.Set(AppConstants.LASTPAGEVISITED, "PREFERENCES");
        props.parent.setState({"lastPageVisited": "PREFERENCES"});
        setSettingsDialogOpen(isOpen);
    };

    const handleMenuExpantion = (menuNode: menuNodes) => {
        switch(menuNode)
        {
            case menuNodes.HISTORY_MENU:
                setHistoryMenuExpanded(!historyMenuExpanded);
                break;
            case menuNodes.DATASERVICES_MENU:
                setDataServicesMenuExpanded(!dataServicesMenuExpanded);
                break;
            case menuNodes.DICTIONARY_MENU:
                setDictionaryMenuExpanded(!dictionaryMenuExpanded);
                break;
        }
    };

    const handleNavigation = (page:string) => {
        // Save what page we navigated to in state so we can render the
        // menu drwaer correctly the next time the user opens it.
        LocalStorage.Set(AppConstants.LASTPAGEVISITED, page);
        props.parent.setState({"lastPageVisited": page});
        props.parent.setState({"isDrawerOpen": false});

        setSelectedIndex(getPageIndex(page));
    };

    const handleDownLoadDataClick = (mode:number) => {
        props.parent.loadTestData(mode);
        LocalStorage.Set(AppConstants.LASTPAGEVISITED, "TEST");
        props.parent.setState({ 
            "isDrawerOpen": false,
            "lastPageVisited": "TEST"});
    }

    const handleUploadLoadDataClick = () => {
        props.parent.uploadTestData();
    };

    const handlePreferencesChange = (name: string) => (event: any) => {
        let value = event.target.value;

        setPreferences(prevState => {
            return {...prevState, [name]: value}
        });
    };

    const { classes } = props;
        
    return (
        <Drawer open={props.isDrawerOpen} onClose={toggleDrawer(false)}>
            <div
                tabIndex={0}
                role="button"
            >
                <div className={classes.list}>
                    <List>
                        <ListItem 
                            button
                            selected={selectedIndex === pageIndex.TEST}
                            onClick={() => handleNavigation("TEST")}
                        >
                            <ListItemIcon><School/></ListItemIcon>
                            <ListItemText primary={"Testing"} />
                        </ListItem>
                        <ListItem 
                            button
                            onClick={() => handleMenuExpantion(menuNodes.DICTIONARY_MENU)}
                        >
                            <ListItemIcon><Search/></ListItemIcon>
                            <ListItemText primary={"Dictionary"} />
                            {dictionaryMenuExpanded ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={dictionaryMenuExpanded}>
                            <List>
                                <ListItem
                                    button
                                    className={classes.nested}
                                    selected={selectedIndex === pageIndex.DICTIONARY}
                                    onClick={() => handleNavigation("DICTIONARY")}
                                >
                                    <ListItemIcon><Search /></ListItemIcon>
                                    <ListItemText primary={"Look Up"} />
                                </ListItem>
                                <ListItem
                                    button
                                    className={classes.nested}
                                    selected={selectedIndex === pageIndex.MYWORDS}
                                    onClick={() => handleNavigation("MYWORDS")}
                                >
                                    <ListItemIcon><Search /></ListItemIcon>
                                    <ListItemText primary={"My Words"} />
                                </ListItem>
                                <ListItem
                                    button
                                    className={classes.nested}
                                    selected={selectedIndex === pageIndex.HSKLEVELS}
                                    onClick={() => handleNavigation("HSKLEVELS")}
                                >
                                    <ListItemIcon><Search /></ListItemIcon>
                                    <ListItemText primary={"HSK Levels"} />
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem 
                            button
                            selected={selectedIndex === pageIndex.SENTENCES}
                            onClick={() => handleNavigation("SENTENCES")}
                        >
                            <ListItemIcon><Notes /></ListItemIcon>
                            <ListItemText primary={"Sentences"} />
                        </ListItem>
                        <ListItem 
                            button  
                            onClick={() => handleMenuExpantion(menuNodes.HISTORY_MENU)}
                        >
                            <ListItemIcon><History/></ListItemIcon>
                            <ListItemText primary={"History"} />
                            {historyMenuExpanded ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={historyMenuExpanded}>
                            <List disablePadding>
                                <ListItem 
                                    button 
                                    className={classes.nested}
                                    selected={selectedIndex === 2} 
                                    onClick={() => handleNavigation("HISTORY")}
                                >
                                    <ListItemIcon><History/></ListItemIcon>
                                    <ListItemText primary={"Testing"} />
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem
                            button
                            onClick={() => handleMenuExpantion(menuNodes.DATASERVICES_MENU)}
                        >
                            <ListItemIcon><Cached /></ListItemIcon>
                            <ListItemText primary={"Data Services"} />
                            {dataServicesMenuExpanded ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={dataServicesMenuExpanded}>
                            <List disablePadding>
                                <ListItem
                                    button
                                    className={classes.nested}
                                    onClick={() => handleDownLoadDataClick(1)}
                                >
                                    <ListItemIcon><CloudDownload /></ListItemIcon>
                                    <ListItemText primary={"Test"} />
                                </ListItem>
                                <ListItem
                                    button
                                    className={classes.nested}
                                    onClick={() => handleDownLoadDataClick(3)}
                                >
                                    <ListItemIcon><CloudDownload /></ListItemIcon>
                                    <ListItemText primary={"Learn"} />
                                </ListItem>
                                <ListItem
                                    button
                                    className={classes.nested}
                                    onClick={() => handleDownLoadDataClick(6)}
                                >
                                    <ListItemIcon><CloudDownload /></ListItemIcon>
                                    <ListItemText primary={"Practice"} />
                                </ListItem>
                                <ListItem
                                    button
                                    className={classes.nested}
                                    onClick={() => handleDownLoadDataClick(2)}
                                >
                                    <ListItemIcon><CloudDownload /></ListItemIcon>
                                    <ListItemText primary={"Download Oldest Test Data"} />
                                </ListItem>
                                <ListItem
                                    divider
                                    button
                                    className={classes.nested}
                                    onClick={() => handleDownLoadDataClick(4)}
                                >
                                    <ListItemIcon><CloudDownload /></ListItemIcon>
                                    <ListItemText primary={"Download Recent Failures"} />
                                </ListItem>
                                <ListItem
                                    divider
                                    button
                                    className={classes.nested}
                                    onClick={handleUploadLoadDataClick}
                                >
                                    <ListItemIcon><CloudUpload /></ListItemIcon>
                                    <ListItemText primary={"Upload Test Data"} />
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem
                            button
                            selected={selectedIndex === pageIndex.PREFERENCES}
                            onClick={() => handleSettingsDialogToggle(true)}>
                            <ListItemIcon><Settings /></ListItemIcon>
                            <ListItemText>Preferences</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><Info /></ListItemIcon>
                            <ListItemText>Version 4.3</ListItemText>
                        </ListItem>
                    </List>
                    <Dialog
                        open={settingsDialogOpen}
                        onClose={() => handleSettingsDialogToggle(false)}
                        aria-labelledby="form-dialog-settings"
                    >
                        <DialogTitle id="form-dialog-settings">Preferences</DialogTitle>
                        <DialogContent>
                            <div>
                            <FormControl className={classes.formControl}>
                                <NativeSelect
                                    value={preferences.characterPreference}
                                    onChange={handlePreferencesChange('characterPreference')}
                                    input={<Input name="characterPreference" id="characterPreference-native-label-placeholder" />} >
                                    <option value="Simplified">Simplified (简体字)</option>
                                    <option value="Traditional">Traditional (繁体字)</option>
                                </NativeSelect>
                                <FormHelperText>Character Preference</FormHelperText>
                            </FormControl>
                            </div>
                            <div>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    id="filled-number"
                                    value={preferences.maximumCharactersPerTestSession}
                                    onChange={handlePreferencesChange('maximumCharactersPerTestSession')}
                                    type="number"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    variant="filled"
                                />
                                <FormHelperText>Characters Per Test Session</FormHelperText>
                            </FormControl>
                            </div>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleSaveSettings} color="primary">
                            Ok
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </Drawer>    
    );
}

export default withStyles(styles)(DrawerList);