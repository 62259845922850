import { useState, useEffect} from 'react';
import TestPage from './testPage';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import * as dbUtils from './indexdbUtils';
import { Statistic } from './statistics';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  regular: {
    height: 55,
  },
  streak: {
    flexGrow: 1,
  },
  progressCounter: {
    align: 'right',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  toolbar: {
    minHeight:50,
    maxHeight:50
  }
});


interface HeaderProps {
  idVocab?: number,
  idTestMethod?: number,
  parent: TestPage
}

const HeaderBar = (props: HeaderProps) => {

  const { idVocab, idTestMethod, parent } = props;
  const [streak, setStreak] = useState<string>();

  useEffect(() => {
    if(idVocab && idTestMethod)
      loadStats(idVocab, idTestMethod);

  }, [idVocab, idTestMethod]);

  const loadStats = async (idVocab: number, idTestMethod: number) => {
    await dbUtils.getStatistics(idVocab!, idTestMethod!).then((stats: Statistic) => {
      let streakValue = stats.formatStreak();
      setStreak(streakValue);
    });
  }

  const toggleDrawer = (open: boolean) => () => {
    parent.props.parent.setState({ isDrawerOpen: open });
  };

  let progressCounter = (idVocab) ? parent.props.position + 1 + " of " + parent.props.vocabulary.length : "";
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <AppBar position="relative">
        <Toolbar className={classes.toolbar}>
          <IconButton onClick={toggleDrawer(true)} className={classes.menuButton} color="inherit" aria-label="Menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.streak} style={{textAlign: 'center'}}>
          {streak ?? ""}
          </Typography>
          <Typography variant="h6" color="inherit" className={classes.progressCounter}>
          {progressCounter}
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default HeaderBar;