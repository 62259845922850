import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Page from './index';
import { Sentences, Sentence, getAllSentences, addUpdateSentence } from './sentences';
import * as indexDBUtils from './indexdbUtils';
import './sentencePage.scss';
import { getPinyinFromCharacterString, pinyinMatchesSearchTerm, updatePunctuation } from './utilities';
import update from 'immutability-helper';


const styles = (theme:any) => ({
    root: {
      width: '100%'
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 10,
    },
    search: {
      position: 'relative' as 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginRight:'20px',
        width: '100%',
      },
    },
    searchIcon: {
      width: theme.spacing(5),
      height: '100%',
      position: 'absolute' as 'absolute',
      pointerEvents: 'none' as 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(6),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 200,
      },
    },
    sentenceInput: {
        height: '0px',
        padding: '17px'
      },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      }
    },
    toolbar: {
      minHeight: 50,
      maxHeight: 50
    }
  });

interface MyProps {
    classes: any,
    parent: Page,
    position: number
  }
  
  interface MyState {
    searchTerm: string,
    allSentences: Sentence[]; // allSentences will be the full list of all sentences,
    visibleSentences: Sentence[];    // while sentences may be filtered for the GUI.
    sentenceEdit: Sentence;
    elementFocused: string;
    selectedSentence?: number;
  };

  const SentencePage = (props: MyProps) => {

  let blankSentence = new Sentence(-1);

  let defaultState = {
    searchTerm:"",
    allSentences: [],
    visibleSentences: [],
    sentenceEdit: blankSentence,
    elementFocused: "",
    selectedSentence: undefined
  };

  const [state, setState] = useState<MyState>(defaultState);

  useEffect(() => {
    if(!state.sentenceEdit)
    {
      // Generate a new id for entering a new sentence.
      indexDBUtils.getNewKey("Sentences").then((key: number) => {
        let blankSentence = new Sentence(key);

        setState({...state, sentenceEdit: blankSentence});
      });
    }

    console.log(`Editing Sentence: ${state.sentenceEdit.id}`);
  });

  useEffect(() => {
    if(state.allSentences?.length === 0)
    {
      // Get all the sentences and display them.
      getAllSentences().then(results => {
        if(results.length > 0)  
          setState({...state, allSentences: results, visibleSentences: results});
      });
    }
  });

  const onSentenceEdit = (sentence: Sentence) => {
    // Update the edit dialog.
    // translate the pinyin from the character sentence if needed.
    sentence.pinyin = sentence.pinyin || getPinyinFromCharacterString(sentence.character);
    setState({ ...state, selectedSentence: sentence.id });
    setState({...state, sentenceEdit: sentence });
  };

  const sentenceSave = () => {
    let { id, character, english, pinyin } = state.sentenceEdit;

    // Validate the sentence input
    if(!(character === "" && english === "" && pinyin === ""))
    {
      character = updatePunctuation(character, true);
      english = updatePunctuation(english, true);

      // Populate the pinyin if it is blank.
      pinyin = pinyin || getPinyinFromCharacterString(character);

      let sentence: Sentence = 
        new Sentence(id, character, english, pinyin, true);

      setState({ ...state, sentenceEdit: sentence });

      // AddUpdate Sentence data in indexDB.
      addUpdateSentence(sentence);

      let allSentencesIndex = state.allSentences.findIndex((x) => x.id === id);
      let sentencesIndex = state.visibleSentences.findIndex((x) => x.id === id);

      if(allSentencesIndex === -1)
      {
        setState(update(state, {
          allSentences: {
            $unshift: [sentence]
          },
          visibleSentences: {
            $unshift: [sentence]
          },    
        }));
      }
      else {
        setState(update(state, {
          allSentences: {
            [allSentencesIndex]: {
              $set: sentence
            }
          },
          visibleSentences: {
            [sentencesIndex]: {
              $set: sentence
            }
          }
        }));
      }
    }
  };

  const sentenceCancel = async () => {
      await indexDBUtils.getNewKey("Sentences").then((key: number) => {
        let blankSentence = new Sentence(key);
        setState({ ...state, sentenceEdit: blankSentence, selectedSentence: undefined });
      })
  };

  const sentenceDelete = () => {
    indexDBUtils.removeKey("Sentences", state.sentenceEdit.id).then(() => {
      let blankSentence = new Sentence(state.sentenceEdit.id);

      let allSentences = state.allSentences.filter((x) => x.id !== state.sentenceEdit.id);
      let visibleSentences = state.visibleSentences.filter((x) => x.id !== state.sentenceEdit.id);

      setState({ ...state, 
        sentenceEdit: blankSentence,
        selectedSentence: undefined,
        allSentences: allSentences,
        visibleSentences: visibleSentences });
    })
  };

  const updateInput = (fieldName: string, value: any) => {
    setState(prevState => ({
      ...state,
      sentenceEdit: {                 // object that we want to update
        ...prevState.sentenceEdit,    // keep all other key-value pairs
        [fieldName]: value            // update the value of specific key
      }
    }));
  };

  const onUpdateChineseField = (value: string) => {
    updateInput("character", value);

    // Update the pinyin in response to a change in the Chinese.
    let pinyin = getPinyinFromCharacterString(value);
    updateInput("pinyin", pinyin);
  };

  const handleSearch = (event:any) => {
    let searchTerm: string = event.target.value.toLowerCase();

    let filtered: Sentence[] = 
        state.allSentences.filter(x => 
          (x.character && x.character.toLowerCase().includes(searchTerm))
          || (x.english && x.english.toLowerCase().includes(searchTerm))
          || pinyinMatchesSearchTerm(x.pinyin, searchTerm)
    );

    setState({ ...state, visibleSentences : filtered });
  };

  const focusInput = (event: any, hasFocus: boolean) => {
    setState({ ...state, elementFocused: (hasFocus) ? event.target.id : "" });
  };

  const toggleDrawer = (isOpen: boolean) => () => {
    props.parent.setState({ isDrawerOpen: isOpen });
  };

  const { classes } = props;
  let inputLabelTop = "-11px";

  return (
    <div className="sentencePage">
      <div className={classes.root}>
        <AppBar position="fixed">
          <Toolbar className={classes.toolbar}>
              <IconButton onClick={toggleDrawer(true)} className={classes.menuButton} color="inherit" aria-label="Open drawer">
                  <MenuIcon />
              </IconButton>
              <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    inputProps={{
                      spellCheck: false,
                      autoCapitalize: "off"
                    }}
                    autoFocus={true}
                    placeholder=""
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    onChange={handleSearch}
                  />
              </div>
              </Toolbar>
        </AppBar>
        <div className="sentenceInput">
            <form noValidate autoComplete="off">
                <div className="inputField">
                    <TextField 
                        id="input-chinese"
                        label="Chinese" 
                        value={state.sentenceEdit.character}
                        onFocus={(event) => focusInput(event, true)}
                        onBlur={(event) => focusInput(event, false)}
                        onChange={(event) => onUpdateChineseField(event.target.value)}
                        variant="outlined"
                        InputLabelProps={(state.sentenceEdit.character !== "" || state.elementFocused === "input-chinese") ? {} : {style: {top: inputLabelTop}}} 
                        InputProps={{ classes: { input: classes.sentenceInput } }}/>
                </div>
                <div className="inputField">
                    <TextField 
                        id="input-pinyin" 
                        label="Pinyin" 
                        value={state.sentenceEdit.pinyin}
                        onFocus={(event) => focusInput(event, true)}
                        onBlur={(event) => focusInput(event, false)}
                        onChange={(event) => updateInput("pinyin", event.target.value)}
                        variant="outlined" 
                        InputLabelProps={(state.sentenceEdit.pinyin !== "" || state.elementFocused === "input-pinyin") ? {} : {style: {top: inputLabelTop}}} 
                        InputProps={{ classes: { input: classes.sentenceInput } }}/>
                </div>
                <div className="inputField">
                    <TextField 
                        id="input-english" 
                        label="English" 
                        value={state.sentenceEdit.english}
                        onFocus={(event) => focusInput(event, true)}
                        onBlur={(event) => focusInput(event, false)}
                        onChange={(event) => updateInput("english", event.target.value)}
                        variant="outlined" 
                        InputLabelProps={(state.sentenceEdit.english !== "" || state.elementFocused === "input-english") ? {} : {style: {top: inputLabelTop}}} 
                        InputProps={{ classes: { input: classes.sentenceInput } }}/>
                </div>
                <div>
                    <Button onClick={sentenceSave} variant="outlined">Save</Button>
                    <Button onClick={sentenceCancel} variant="outlined">Cancel</Button>
                    {state.sentenceEdit.id < 0 &&
                      <Button onClick={sentenceDelete} variant="outlined">Delete</Button>
                    }
                </div>
            </form>
        </div>
        <Sentences parent={onSentenceEdit} sentences={state.visibleSentences} selectedSentence={state.selectedSentence}></Sentences>
      </div>
    </div>
  )}

export default withStyles(styles)(SentencePage);