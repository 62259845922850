import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as LocalStorage from './LocalStorage';
import * as DataServices from './DataServices';
import Dictionary from './Dictionary/dictionary';
import DictionaryPage from './Dictionary/dictionaryPage';
import History from './Dictionary/history'
import TestPage from './testPage';
import SentencePage from './sentencePage';
import TestCycle from './testCycle';
import Vocab from './vocab';
import * as AppConstants from './constants';
import * as Utils from './utilities';
import DrawerList from './drawer';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { blue, yellow } from '@material-ui/core/colors';


var testCycle: TestCycle = new TestCycle();
var dictionary: Dictionary = new Dictionary();

interface PageState {
  position: number,
  vocabulary: Vocab[],
  lastPageVisited: string,
  isDrawerOpen: boolean,
  dictionary: Dictionary,
  insetValue: number
}

// This is the main component that controls the whole testing session.
export default class Page extends React.Component<{}, PageState> {
  constructor(props:any) {
    super(props);

    dictionary = new Dictionary();

    try {
      dictionary.populate();
    }
    catch(err) { alert(err); }
 
    testCycle = new TestCycle();
    testCycle.loadDataFromStorage();

    let lastPageVisted = LocalStorage.GetFromLocalStorage(AppConstants.LASTPAGEVISITED) || "TEST";
    let insetValue = (lastPageVisted ==="MYWORDS") ? 0 : 1;

    this.state = {
      position: testCycle.position,
      vocabulary: testCycle.vocabulary,
      lastPageVisited: lastPageVisted,
      isDrawerOpen: false,
      dictionary: dictionary,
      insetValue: insetValue,
    };
  }

  public setPosition(value: number): boolean
  {
    let newPosition = testCycle.setPosition(value);

    if(newPosition > this.state.vocabulary.length)
    {
      // We are completely done with testing.
      this.setState({ position: newPosition });
      return true;
    }
    else
    {
      this.setState((prevState) => ({ position: testCycle.position }));
      return false;
    }
  }

  public uploadTestData()
  {
    Utils.showHideSpinner({show: true});

    // Upload the test data
    DataServices.uploadTestData().then((value: any) => {

      // load the test data from local storage
      testCycle = new TestCycle();

      // set up our state based on the newly loaded data
      this.setState((state) => ({
        position: testCycle.position,
        vocabulary: testCycle.vocabulary
      }));
     
    }).catch((error: string) => {
      window.alert(error);
    }).finally(() => {
      Utils.showHideSpinner({show: false});
    });
  }

  public loadTestData(mode: number)
  {
    let testedItems = this.state.vocabulary.filter((element) => {
      return (element.result !== null)
    });

    let activeVocabKeys = LocalStorage.GetLocalStorageKeysByPrefix(AppConstants.ACTIVEVOCABUPDATE_PREFIX);

    // Warn the users that they have test results or vocabulary changes that have not yet been uploaded.
    if((testedItems.length !== 0 || activeVocabKeys.length !== 0)
      && (!window.confirm("You have test results or vocabulary changes that have not been uploaded. Downloading new content will overwrite those changes. Are you sure you want to continue?")))
      return;

    Utils.showHideSpinner({show: true});

    // Download the test data and store it in local storage
    DataServices.downloadTestData(mode).then((value: any) => {
      dictionary = new Dictionary();

      try {
        dictionary.populate();
      }
      catch(err) { alert(err); }

      // load the test data from local storage
      testCycle = new TestCycle();
      testCycle.loadDataFromStorage();

      // set up our state based on the newly loaded data
      this.setState((state) => ({
        position: testCycle.position,
        vocabulary: testCycle.vocabulary,
        dictionary: dictionary
      }));

      // Generate a GUID for this downloaded data. Whenever we upload test results we will pass in this
      // GUID to ensure that test results are not uploaded twice.
      LocalStorage.Set(AppConstants.TESTRESULTSGUID, Utils.generateGUID());
    }).catch((error: string) => {
      window.alert(error);
    }).finally(() => {
      Utils.showHideSpinner({show: false});
    });
  }

  render() {
    let featurePage;

    switch(this.state.lastPageVisited)
    {
      case "TEST":
        featurePage = <TestPage position={this.state.position} vocabulary={this.state.vocabulary} parent={this}/>;
        break;

      case "HISTORY":
        featurePage = <History parent={this} page={this.state.lastPageVisited} key={7}></History>;
        break;
      case "MYWORDS":
        featurePage = <History parent={this} page={this.state.lastPageVisited} key={8}></History>;
        break;
      case "HSKLEVELS":
        featurePage = <History parent={this} page={this.state.lastPageVisited} key={9}></History>;
        break;
      case "SENTENCES":
        featurePage = <SentencePage parent={this} position={1}></SentencePage>;
        break;
  
      default:
        featurePage = <DictionaryPage parent={this} dictionary={this.state.dictionary}></DictionaryPage>;
        break;
    }

    return (
      <div>
        <DrawerList parent={this} isDrawerOpen={this.state.isDrawerOpen}></DrawerList>
        {featurePage}
      </div>);
  }
}

// ========================================
const theme = createTheme({
  palette: {
    primary: blue,
    secondary: yellow,
  },
});

ReactDOM.render(<MuiThemeProvider theme={theme}><div id="spinBlocker" /><Page /></MuiThemeProvider>, document.getElementById("root"));

// ========================================

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();